<template>
  <div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="orderNumber" label="订单编号"></el-table-column>
      <el-table-column prop="userId" label="下单人id"></el-table-column>
      <el-table-column
        prop="createTime"
        sortable
        label="下单时间"
      ></el-table-column>
      <el-table-column prop="payTime" label="支付时间"></el-table-column>
      <el-table-column prop="addrName" label="联系人"></el-table-column>
      <el-table-column prop="addrPhone" label="联系电话"></el-table-column>
      <el-table-column label="地址" width="200">
        <template slot-scope="scope">
          {{ scope.row.addrCity + " " + scope.row.addrDetail }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 0">无效</span>
          <span v-else-if="scope.row.type === 1">已加购</span>
          <span v-else-if="scope.row.type === 2">待付款</span>
          <span v-else-if="scope.row.type === 3">待发货</span>
          <span v-else-if="scope.row.type === 4">已发货</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="out(scope.row)"
            v-if="scope.row.type === 3"
            >发货</el-button
          >
          <el-button type="danger" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[2, 5, 10]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import API from "../utils/request";
const url = "/api/order/";

export default {
  name: "Order",
  data() {
    return {
      fileList: [],
      options: [],
      text: "",
      user: {},
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      entity: {},
      total: 0,
      dialogFormVisible: false,
    };
  },
  created() {
    this.user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.load();
  },
  methods: {
    out(obj) {
      API.post(`/api/user/set/order/state`, {
        orderNumber: obj.orderNumber,
        type: 4,
      }).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.load();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.load();
        }
      });
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.load();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load();
    },
    load() {
      API.post(`/api/back/select/order/list`, {
        type: 0,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.tableData = Object.values(res.data) || [];
        this.total = res.total;
      });
    },
    del(id) {
      API.post(`/api/user/delete/order`, {
        orderNumber: id.orderNumber,
      }).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.load();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.load();
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
